import React from "react";
import Seo from "../../components/seo";
import ProjectDetail from "../../components/ProjectDetail";
import queryString from "query-string";

const ProjectDetailPage = (props) => {
  const { search } = props.location;
  const { id } = queryString.parse(search);
  return (
    <>
      <Seo title="ProjectDetail" />
      <ProjectDetail {...props} id={id} />
    </>
  );
};

export default ProjectDetailPage;
