import React from "react";
import { LinearProgress, Box } from "@mui/material/";

function LinearProgressWithLabel({ value }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box sx={{ minWidth: 45 }}>
        <p className="percent">{`${Math.round(value)}%`}</p>
      </Box>
    </Box>
  );
}

export default function ProgressBarWithPercent({ percent }) {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={percent} />
    </Box>
  );
}
