const DEV_JIRA_BOARD = [
  "FFTI",
  "EAI",
  "ECI",
  "EIM",
  "LCD",
  "SEAWF",
  "NSCH",
  "CLM",
  "LOT",
  "CID",
  "CLT",
  "PF",
  "LSE",
  "SLM",
  "RPA",
  "UXUI",
  "UX"
];

export default DEV_JIRA_BOARD;
